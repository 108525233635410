(function($) {

    $( document ).ready( function() {

        /*
         * Sticky Header on scroll
         */
        window.addEventListener('scroll', function(e){
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 100,
                header = $('#js-header');
            if (distanceY > shrinkOn) {
                header.addClass('site-head--alt');

            } else {
                if (header.hasClass('site-head--alt')) {
                    header.removeClass('site-head--alt');
                }
            }
        });


        /*
         * Smooth Scroll
         * https://css-tricks.com/snippets/jquery/smooth-scrolling/
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 113
                    }, 1000);
                    return false;
                }
            }
        });


        /*
         * Mobile Nav
         */
        $('.js-menuToggle').on('click', function(e){
            e.preventDefault();
            $('.js-menuToggle').toggleClass('burger--open');
            $('.nav-primary__menu').toggleClass('nav-primary__menu--open');

            $('.site-head').toggleClass('site-head--mobile-open');
        });

        function closeMenu() {
            $('.js-menuToggle').removeClass('burger--open');
            $('.nav-primary__menu').removeClass('nav-primary__menu--open');

            $('.site-head').removeClass('site-head--mobile-open');
        }

        $(window).resize(function(){
            closeMenu();
        });


        /*
         * Slick Slider
         */
        $('.js-slick').slick(); // slick carousel

        // Video slides
        $('.js-slick-video').on('beforeChange', function(event, slick, currentSlide) {
            $('.bannerVideo')[0].pause();
        });

        $('.js-slick-video').on('afterChange', function(event, slick, currentSlide) {
            playSlideVideo();
        });

        playSlideVideo();

        function playSlideVideo() {
            if ($('.slick-active').find('.bannerVideo').length) {
                $('.bannerVideo')[0].play();
                $('.bannerVideo')[0].muted = true;
            }
        }


        /*
         * Stats Counter
         */

        if($('#statsSection').length) {
            $('.stat').hide();
        }
        window.addEventListener('scroll', function(e){

            if($('#statsSection').visible()) {
                //$('.stat').fadeIn();

                $('.stat').each(function(i) {
                    $(this).delay((i++) * 200).fadeTo(500, 1);
                });

            }
        });


    });

})(jQuery); // Fully reference jQuery after this point.
